import { computed, Injectable, signal } from '@angular/core';
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { UsersService } from './users/users.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.digigoldbe;
  user = signal<User | null>(null);
  token = signal<string | null>(null);
  rtoken = signal<string | null>(null);
  jwtTokenPayload = computed(() => this.parseJwt(this.token()));

  constructor(private http: HttpClient, private userService: UsersService) {
    const _userSession = sessionStorage.getItem('user');
    const _tokenSession = sessionStorage.getItem('authToken');
    const _rtokenSession = sessionStorage.getItem('refreshToken');
    if (_userSession != null) {
      this.user.set(JSON.parse(_userSession));
      this.token.set(_tokenSession);
      this.rtoken.set(_rtokenSession);
    }
  }

  logIn(reqBody: any) {
    const url = `${this.apiUrl}/auth/sign-in`;
    return this.http.post(url, reqBody).pipe(
      map((response: any) => {
        if (response.statusCode === 200) {
          sessionStorage.setItem('authToken', response.data.accesstoken);
          sessionStorage.setItem('refreshToken', response.data.refreshtoken);
          this.token.set(response.data.accesstoken);
          this.rtoken.set(response.data.accesstoken);

          this.userService.findUserById(this.jwtTokenPayload().id).subscribe({
            next: (response: any) => {
              this.user.set(response.data);
              sessionStorage.setItem('user', JSON.stringify(this.user()));
            },
          });
          return response;
        }
      })
    );
  }

  parseJwt(token: string | null) {
    if (token == null) return null;
    // Split the JWT into its components
    const base64Url = token.split('.')[1];

    // Replace characters to make it valid Base64
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    // Decode the Base64 string
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    // Parse the JSON payload
    return JSON.parse(jsonPayload);
  }

  logOut() {
    this.user.set(null);
    this.token.set(null);
  }
}
