import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GenericResponse } from '../../models/generic-response.model';
import { catchError, map, Observable } from 'rxjs';
import { error } from 'console';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private apiUrl = environment.digigoldbe;

  constructor(private http: HttpClient) {}

  createUser(phoneNumber: string) {
    const url = `${this.apiUrl}/users/create`;
    return this.http.post(url, { phoneNumber });
  }
  findUserById(id: number){
    const url = `${this.apiUrl}/users/${id}`;
    return this.http.get(url);
  }

  findUserByPhoneNumber(phoneNumber: string) {
    const url = `${this.apiUrl}/users/find/${phoneNumber}`;
    return this.http.get(url);
  }

  updateUserById(userId: number, reqBody: any){
    const url = `${this.apiUrl}/users/${userId}`;
    return this.http.patch(url, reqBody);
  }
}
